export default class Program {
  id = '';
  title = '';
  date = '';
  subTitle = '';
  start = '';
  end = '';
  duration = '';
  programClass = '';
  country = '';
  producer1 = '';
  producer2 = '';
  productionNo = '';
  productionSource = '';
  categoryCode = '';
  accessibleProgramming = '';
  dubbingCredit = '';
  language1 = '';
  language2 = '';
  filmClassification = '';
  timeCredits = '';
  exhibitionCode = '';
  audienceAge = '';
  ethnicProgramType = '';
  ethnicTargetAudience = '';
  specialAttentionCode = '';
  locallyRelevant = '';
}

export const LogFileStructure = [
  {
    field: 'logFormatVersion',
    start: 1,
    end: 1,
    length: 1,
    type: 'numeric',
    format: '',
    default: '2'
  },
  {
    field: 'programClass',
    start: 2,
    end: 4,
    length: 3,
    type: 'alphabetic',
    format: ''
  },
  {
    field: 'affiliationType',
    start: 5,
    end: 6,
    length: 2,
    type: 'numeric',
    format: ''
  },
  {
    field: 'identifier',
    start: 7,
    end: 12,
    length: 6,
    type: 'alphanumeric',
    format: '',
    default: 'OTN1'
  },
  {
    field: 'date',
    start: 13,
    end: 18,
    length: 6,
    type: 'numeric',
    format: 'YYMMDD'
  },
  {
    field: 'start',
    start: 19,
    end: 24,
    length: 6,
    type: 'numeric',
    format: 'HHMMSS'
  },
  {
    field: 'end',
    start: 25,
    end: 30,
    length: 6,
    type: 'numeric',
    format: 'HHMMSS'
  },
  {
    field: 'duration',
    start: 31,
    end: 36,
    length: 6,
    type: 'numeric',
    format: 'HHMMSS'
  },
  {
    field: 'title',
    start: 37,
    end: 86,
    length: 50,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'subTitle',
    start: 87,
    end: 136,
    length: 50,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'producer1',
    start: 137,
    end: 142,
    length: 6,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'producer2',
    start: 143,
    end: 148,
    length: 6,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'productionNo',
    start: 149,
    end: 154,
    length: 6,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'specialAttentionCode',
    start: 155,
    end: 155,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'country',
    start: 156,
    end: 156,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'timeCredits',
    start: 157,
    end: 157,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'exhibitionCode',
    start: 158,
    end: 158,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'productionSource',
    start: 159,
    end: 159,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'audienceAge',
    start: 160,
    end: 160,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'categoryCode',
    start: 161,
    end: 163,
    length: 3,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'accessibleProgramming',
    start: 164,
    end: 165,
    length: 2,
    type: 'alphabetic',
    format: ''
  },
  {
    field: 'dubbingCredit',
    start: 166,
    end: 166,
    length: 1,
    type: 'numeric',
    format: ''
  },
  {
    field: 'ethnicProgramType',
    start: 167,
    end: 167,
    length: 1,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'language1',
    start: 168,
    end: 170,
    length: 3,
    type: 'alphabetic',
    format: ''
  },
  {
    field: 'language2',
    start: 171,
    end: 173,
    length: 3,
    type: 'alphabetic',
    format: ''
  },
  {
    field: 'ethnicTargetAudience',
    start: 174,
    end: 175,
    length: 2,
    type: 'alphabetic',
    format: ''
  },
  {
    field: 'locallyRelevant',
    start: 176,
    end: 177,
    length: 2,
    type: 'alphanumeric',
    format: ''
  },
  {
    field: 'filler',
    start: 178,
    end: 192,
    length: 15,
    type: 'alphanumeric',
    format: ''
  }
];

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Program = props => {
  const count = parseInt(props.index) + 1;
  const rowClass = props.isSelected ? "selected" : "";

  // const ifChecked = props.isSelected ? true : false;
  return (
    <tr className={rowClass}>
      <td>
        <input
          type='checkbox'
          onClick={() => {
            props.onProgramCheckboxClick(props.program.id);
          }}
        />
      </td>
      <th scope='row'>{count}</th>

      <td>{props.program.start}</td>
      <td>{props.program.end}</td>
      <td>{props.program.title}</td>
      <td className='actions'>
        <FontAwesomeIcon
          icon='edit'
          onClick={() => {
            props.editProgram(props.program.id, props.index);
          }}
        />{" "}
        <FontAwesomeIcon
          icon='trash'
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you want to delete selected program ?"
              )
            ) {
              props.deleteProgram(props.program.id, props.index);
            }
          }}
          // onClick={() => {
          //   props.deleteProgram(props.program.id, props.index);
          // }}
        />
      </td>
    </tr>
  );
};
export default Program;

import React, { Fragment } from "react";
import Calendar from "react-calendar";
import { dateFormat } from "../service/Util";
import ApiService, { API_ENDPOINTS } from "../service/ApiService";

class CopyPrograms extends React.Component {
  constructor() {
    super();
    this.state = {
      copyToDate: ""
    };
  }
  CopyPrograms = () => {
    if (!this.state.copyToDate) {
      alert("Please Select A Date To Paste Coppied Programs ");
      return;
    }
    let data = {
      programIDs: this.props.selectedPrograms,
      copyToDate: dateFormat(this.state.copyToDate)
    };

    ApiService.request("POST", API_ENDPOINTS.COPY_PROGRAMS_BY_IDS, data);
  };

  dateChange = date => {
    this.setState({ copyToDate: date });
  };

  render() {
    return (
      <Fragment>
        <div className='col'>
          <p className=' font-weight-bold'>Select A Date</p>
          <Calendar onChange={this.dateChange} />
          <button
            type='button'
            className='btn btn-primary mx-auto mt-2 '
            onClick={this.CopyPrograms}
          >
            Copy Programs
          </button>
        </div>
        <div />
      </Fragment>
    );
  }
}

export default CopyPrograms;

import React from 'react';
import { connect } from 'react-redux';
import Toast from './Toast';
import { removeToast } from '../redux/action-creators';
import '../css/toast.scss';
const Toasts = ({ toasts, removeToast }) => {
  return toasts.length > 0 ? (
    <div className='toast-stack' aria-live='polite' aria-atomic='true'>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        {toasts.map(toast => {
          const { id } = toast;
          return <Toast toast={toast} key={id} removeToast={() => removeToast(id)} />;
        })}{' '}
      </div>
    </div>
  ) : (
    ''
  );
};

function mapStateToProps(state) {
  return {
    toasts: state.toasts
  };
}

export default connect(
  mapStateToProps,
  { removeToast }
)(Toasts);

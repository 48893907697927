const programSelectOptions = {
  programClass: {
    title: "Programming Class",
    options: {
      LOC: "Commercial message- local",
      COM: "Commercial message - national",
      COR: "Cornerstone",
      GIA: "Giveaways",
      PGI: "Infomercial",
      INS: "Institutional message",
      ID: "Licensee identification",
      MAG: "Canadian entertainment magazine",
      MER: "Merchandising",
      MOS: "Mosaic",
      MVC: "Music video clips",
      OFF: "Off air",
      OFT: "Off air (technical)",
      PGR: "Program",
      PFS: "Program first segment",
      SEG: "Program second & subsequent segment(s)",
      PRC: "Promotion for a Canadian program",
      PRO: "Promotion for a non-Canadian program",
      PSA: "Public service announcements",
      NRN: "Public service announcements (alternative logging procedure)",
      SO: "Sign on or sign off",
      SOL: "Solicitation message",
      SPO: "Sponsorship message"
    }
  },
  country: {
    title: "Country of Origin",
    options: {
      1: "Canada",
      2: "United States",
      7: "Other",
      8: "Québec"
    }
  },
  productionSource: {
    title: "Production Source",
    options: {
      1: "TV station (licensee) / Discretionary service (licensee)",
      2: "Related production company",
      3: "Other TV station or programming service ",
      4: "Television network ",
      5: "Canadian independent producer ",
      6: "Co-venture ",
      7: "programs from any government and productions of the National Film Board ",
      8: "Programs from any source that are not accredited as Canadian programs ",
      9: "Treaty co-production"
    }
  },
  categoryCode: {
    title: "Category Code",
    options: {
      "010": "News",
      "02A": "Analysis and interpretation",
      "02B": "Long-form documentary",
      "030": "Reporting and actualities",
      "040": "Religion",
      "05A": "Formal education and pre-school",
      "05B": "Informal education / Recreation and leisure",
      "06A": "Professional sports",
      "06B": "Amateur sports",
      "07A": "Ongoing dramatic series",
      "07B": "Ongoing comedy series (sitcoms)",
      "07C": "Specials, mini-series and made-for-TV feature films",
      "07D": "Theatrical feature films airedon TV",
      "07E": "Animatedtelevision programs and films",
      "07F":
        "Programs of comedy sketches, improvisation, unscriptedworks, stand-up comedy",
      "07G": "Other drama",
      "08A": "Music and dance",
      "08B": "Music video clips",
      "08C": "Music video programs",
      "090": "Variety",
      "100": "Game shows",
      "11A":
        "General entertainment & human interest / Canadian entertainment magazine programs",
      "11B": "Reality television",
      "120": "Interstitials",
      "130": "Public service announcements",
      "140": "Infomercials, promotional and corporate videos",
      "150": "Filler programming(Only applies to Discretionary Services)"
    }
  },
  accessibleProgramming: {
    title: "Accessible Programming",
    options: {
      AD: "Program contains audio description ",
      CA: "contains both closed captioning and audio description ",
      CC: "Program contains closed captioning ",
      CD: "Program contains both closed captioning and described video",
      DV: "Program contains described video"
    }
  },
  specialAttentionCode: {
    title: "Special Attention Code",
    options: {
      1: "CBC affiliates, indicates a network program which is neither reserved nor available to the affiliates",
      2: "CTV network and its affiliates indicates a network sales program and CBC, SRC and their affiliates indicates reserved network programming status.",
      3: "CTV network and its affiliates3	CTV network and its affiliates indicates a local sales program and CBC, SRC and their affiliates indicates available programming status"
    }
  },

  dubbingCredit: {
    title: "Dubbing Credit",
    options: {
      0: "25% Canadian Content Credit applies to the broadcast of sports events originating outside Canada",
      1: "25% Canadian Content Credit",
      2: "50% Canadian Content Credit",
      3: "150% Canadian Content Credit",
      4: "100% plus 25% Canadian Content Credit",
      5: "150% plus 25% Canadian Content Credit",
      6: "33% Canadian Content Credit",
      7: "33% Canadian Content Credit for non-Canadian program ",
      8: "100% plus 33% Canadian Content Credit",
      9: "150% plus 33% Canadian Content Credit"
    }
  },
  language1: {
    title: "Language 1",
    options: {
      ENG: "English",
      GRE: "Greek",
      FRE: "French"
    }
  },
  language2: {
    title: "Language 2",
    options: {
      ENG: "English",
      GRE: "Greek",
      FRE: "French"
    }
  },

  locallyRelevant: {
    title: "Locally Relevant",
    options: {
      RL: "RL"
    }
  },
  ethnicTargetAudience: {
    title: "Ethnic Target Audience",
    options: {
      CA: "Canadian",
      GA: "Greek"
    }
  },
  // filmClassification: {
  //   title: "Film Classification",
  //   options: {}
  // },
  timeCredits: {
    title: "Time Credits",
    options: {
      "4": "150 % credit is given ",
      "5": "150 % credit is NOT given "
    }
  },
  exhibitionCode: {
    title: "Exhibition Code",
    options: {
      "1": "Original exhibition ",
      "2": "Original first-run program ",
      "3": "Repeat exhibition of a program",
      "4": "Live broadcast"
    }
  },
  audienceAge: {
    title: "Audience Target Age",
    options: {
      1: "Preschool Children (0-5 years)",
      2: "Children (6-12 years)",
      3: "Teenagers (13-17 years)",
      4: "Adults (18 years and over)"
    }
  },
  ethnicProgramType: {
    title: "Ethnic Program Type",
    options: {
      X: "Licensee is not required ",
      A: "Non english program",
      B: "English/French program",
      C:
        "A program in English or in French that is directed toward a distinct ethnic group whose mother tongue is included in Type A",
      D: "A bilingual program ",
      E:
        "A program in English or in French that is directed toward ethnic groups"
    }
  }
  // ethnicTargetAudience: {
  //   title: "Ethnic Target Audience",
  //   options: {
  //     GA: "Greek"
  //   }
  // }
};
export default programSelectOptions;

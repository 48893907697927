import { TOGGLE_CALENDAR } from '../action-creators';

export const toggleCalendar = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CALENDAR:
      return !state;
    default:
      return state;
  }
};

import { USER_TOKEN, USER_ID } from './AuthService';
import store from '../redux/store';
import { showErrorToast, showSuccessToast } from '../redux/action-creators';
import axios from 'axios';
const ApiService = {
  request(
    method = 'POST',
    endpoint,
    data = {},
    successCallback,
    errorCallback,
    showErrorAlert = true
  ) {
    let requestParams = { url: Baseurl.url + endpoint, method: method };
    let token = localStorage.getItem(USER_TOKEN);

    //   token: localStorage.getItem(USER_TOKEN)
    //    user_id: localStorage.getItem(USER_ID)
    // };

    let isFormData = data instanceof FormData ? true : false;
    let headers = {
      'Content-Type': !isFormData ? 'application/json;charset=UTF-8' : 'multipart/form-data'
    };
    //let headers = { 'Content-Type': 'multipart/form-data' };

    if (token) {
      Object.assign(headers, { Authorization: `Bearer ${token}` });
    }

    Object.assign(requestParams, { headers: { ...headers } });

    if (method === 'POST') {
      Object.assign(data, {
        station_id: parseInt(localStorage.getItem('station_id'))
      });
    }

    let params = JSON.stringify(data);
    if (method === 'POST') {
      Object.assign(requestParams, {
        data: params
      });
    }

    // let params = JSON.stringify({ data });
    // if (method === 'POST') {
    //   Object.assign(requestParams, JSON.stringify(data));
    //   console.log(requestParams);
    // }

    axios(requestParams).then(
      resp => {
        if (resp.data.status === true) {
          handleApiSuccess(resp.data, successCallback, store);
        } else {
          handleApiError(resp.data, showErrorAlert, errorCallback, store);
        }
      },
      err => handleApiError(err, showErrorAlert, errorCallback, store)
    );
  }
  /*

    Object.assign(data, {
      token: localStorage.getItem(USER_TOKEN),
      user_id: localStorage.getItem(USER_ID),
      station_id: parseInt(localStorage.getItem('station_id'))
    });
    fetch(Baseurl.url + endpoint, {
      method: method,
      // headers: {
      //   token: localStorage.getItem(USER_TOKEN),
      //   user_id: localStorage.getItem(USER_ID),
      //   station_id: parseInt(localStorage.getItem('station_id'))
      // },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(
        resp => {
          if (resp.status === true) {
            handleApiSuccess(resp, successCallback, store);
          } else {
            handleApiError(resp, showErrorAlert, errorCallback, store);
          }
        },
        err => handleApiError(err, showErrorAlert, errorCallback, store)
      );
  } 
};*/
};

const handleApiError = (err, showAlert, errorCallback, store) => {
  if (showAlert) {
    let msg = err.message ? err.message : 'Something went wrong, please try again later';
    store.dispatch(showErrorToast(msg));
  }
  if (errorCallback && typeof errorCallback === 'function') {
    errorCallback(err);
  }
};
const handleApiSuccess = (resp, successCallback, store) => {
  if (resp.message && resp.message !== 'success') {
    store.dispatch(showSuccessToast(resp.message));
  }
  if (successCallback && typeof successCallback === 'function') {
    successCallback(resp);
  }
};
export const Baseurl = {
  url: process.env.REACT_APP_API_URL
};
export const API_ENDPOINTS = {
  LOGIN: 'user/login',
  LOGOUT: 'logout',
  PROGRAM_DETAIL: 'program-detail',
  PROGRAM_LIST: 'program-list',
  DELETE_PROGRAM: 'delete-program',
  SAVE_PROGRAM: 'add-update-programs',
  COPY_PROGRAMS: 'copy-programs-by-date-range',
  FETCH_PROGRAMS_BY_MONTH: 'programs-by-month',
  COPY_PROGRAMS_BY_IDS: 'copy-programs-by-ids',
  CLEAR_DAY: 'clear-day',
  STATIONS_LIST: 'stations',
  ADD_STATION: 'stations'
};
export default ApiService;

import ApiService, { API_ENDPOINTS } from './ApiService';

const IS_AUTHENTICATED = 'isAuthenticated';
export const USER_TOKEN = 'userToken';
export const USER_ID = 'userId';
export const AuthService = {
  isLoggedIn() {
    //check redux or localstorage to check if user is logged in
    return JSON.parse(localStorage.getItem(IS_AUTHENTICATED));
  },
  authenticate(creds, successCallback) {
    //call login API
    ApiService.request('POST', API_ENDPOINTS.LOGIN, creds, resp => {
      localStorage.setItem(USER_TOKEN, resp.data.token);
      localStorage.setItem(USER_ID, resp.data.user_id);
      localStorage.setItem(IS_AUTHENTICATED, true);
      // localStorage.setItem('station_identifier', 'OTN1');
      // localStorage.setItem('station_id', 1);
      successCallback(resp);
    });
    //comment the below line when linked with Login API
    // localStorage.setItem(IS_AUTHENTICATED, true);
    //setTimeout(successCallback);
  },
  signout(cb) {
    //call logout API
    localStorage.setItem(IS_AUTHENTICATED, false);
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem('station_identifier');
    localStorage.removeItem('station_id');

    setTimeout(cb);
  }
};
export default AuthService;

import { createStore } from 'redux';
import rootReducer from './reducers';

const defaultStore = {
  toasts: [],
  station: {},
  showCalendar: false
};
//Create a redux store by passing the combined reducers or a single reducer
const store = createStore(
  rootReducer,
  defaultStore,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

import React, { Fragment } from 'react';
import Calendar from 'react-calendar';
import ApiService, { API_ENDPOINTS } from '../service/ApiService';
import { dateFormat } from '../service/Util';
class CopyDays extends React.Component {
  constructor() {
    super();
    this.state = {
      fromStartDate: '',
      fromEndDate: '',
      toStartDate: '',
      disableSubmit: false
    };
  }
  handleRangeChange = dateRange => {
    this.setState({ fromStartDate: dateRange[0], fromEndDate: dateRange[1] });
  };
  resetDateRange = date => {
    if (
      (this.state.fromStartDate && this.state.fromEndDate) ||
      (!this.state.fromStartDate && !this.state.fromEndDate)
    ) {
      this.setState({ fromStartDate: date, fromEndDate: '' });
    }
  };
  updateToStartDate = date => {
    this.setState({ toStartDate: date });
  };
  toggleLoading() {
    this.setState({ disableSubmit: !this.state.disableSubmit });
  }
  processCopyDays = () => {
    if (!this.state.fromStartDate) {
      alert('Please select a Start date to copy from');
      return;
    }
    if (!this.state.toStartDate) {
      alert('Please select a date to copy into');
      return;
    }
    //this.disableCopyButton = true;
    let data = {
      fromStartDate: dateFormat(this.state.fromStartDate),
      fromEndDate: this.state.fromEndDate
        ? dateFormat(this.state.fromEndDate)
        : dateFormat(this.state.fromStartDate),
      toStartDate: dateFormat(this.state.toStartDate)
    };
    this.toggleLoading();
    ApiService.request(
      'POST',
      API_ENDPOINTS.COPY_PROGRAMS,
      data,
      () => {
        //alert('Programs Copied successfully!');
        this.toggleLoading();
      },
      () => this.toggleLoading()
    );
  };
  render() {
    return (
      <Fragment>
        <div className='row'>
          <div className='col'>
            <p className='text-center font-weight-bold'>Select Date Range - Copy From</p>
            <Calendar
              onChange={this.handleRangeChange}
              onClickDay={this.resetDateRange}
              returnValue='start'
              selectRange={true}
            />
          </div>
          <div className='col'>
            <p className='text-center font-weight-bold'>Select Date - Copy To</p>
            <Calendar onChange={this.updateToStartDate} />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <button
              type='button'
              className='btn btn-primary mx-auto mt-2'
              onClick={this.processCopyDays}
              disabled={this.state.disableSubmit}
            >
              Copy Days
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CopyDays;

import React from 'react';
import Program from '../models/Program';
import programSelectOptions from '../program-details';
import { dateFormat } from '../service/Util';
import Loading from './Loading';
import TimeField from 'react-simple-timefield';
import { showErrorToast } from '../redux/action-creators';
import { connect } from 'react-redux';

//import Program from './models/Program';
//import PropTypes from "prop-types"
class ProgramDetailsForm extends React.Component {
  time = { start: '', end: '' };
  constructor(props) {
    super(props);
    this.state = {
      program: this.props.program || new Program(),
      isSubmitting: false
    };
  }
  handleInputChange = e => {
    let program = { ...this.props.program, [e.target.name]: e.target.value };
    this.props.handleInputChange(program);
  };
  handleTimeChange = (type, value) => {
    this.time[type] = value;
    const duration = this.durationCalculation();
    let program = { ...this.props.program, duration: duration, [type]: value };
    this.props.handleInputChange(program);
  };
  resetForm = () => {
    const program = new Program();
    this.props.handleInputChange(program);
  };

  formatDate = () => {
    return dateFormat(this.props.date);
  };
  validate = () => {
    if (!this.props.program.title) {
      this.props.showErrorToast('Title is Required feild');
      return false;
    } else if (
      !this.props.program.start ||
      !this.props.program.end ||
      !this.props.program.duration
    ) {
      this.props.showErrorToast("START Time and END Time and Duration are Required Feild's");
      return false;
    }
  };
  onSubmit = e => {
    e.preventDefault();

    if (this.validate() === false) {
      return;
    } else {
      this.props.onFormSubmit(this.props.program);
    }
  };
  renderInputValue = key => {
    if (!this.props.program[key]) {
      return '';
    }
    return this.props.programSelectOptions[key]['options'][this.props.program[key]];
  };
  renderSelectInputs = (key, obj) => {
    return (
      <div className='p-1 col-6' key={key}>
        <label>{obj['title']}</label>
        <div className='form-row'>
          <div className='form-group col-md-3'>
            <select
              className='form-control'
              id={key}
              name={key}
              value={this.props.program[key]}
              onChange={this.handleInputChange}
            >
              <option value='' />
              {Object.entries(obj.options).map(([key, option]) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group col-md-9'>
            <input
              className='form-control'
              type='text'
              value={this.renderInputValue(key)}
              id={key + 'Input'}
              readOnly
            />
          </div>
        </div>
      </div>
    );
  };

  calculateTime(diff) {
    let msec = diff;
    let hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    let mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    let ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if (hh < 10) hh = '0' + hh;
    if (mm < 10) mm = '0' + mm;
    if (ss < 10) ss = '0' + ss;
    return `${hh}:${mm}:${ss}`;
  }
  durationCalculation() {
    if (!this.time.start || !this.time.end) {
      return '00:00:00';
    } else {
      let start = new Date('1970-01-01T' + this.time.start + 'Z');
      let end = new Date('1970-01-01T' + this.time.end + 'Z');
      let diff = end - start;
      //if diff is negetive
      if (diff < 0) {
        return this.calculateTime(diff + 86400000);
      } else {
        return this.calculateTime(diff);
      }
    }
  }

  render() {
    if (this.props.formLoading) {
      return <Loading />;
    } else {
      return (
        <form onSubmit={this.onSubmit}>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <b>Individual Program Details</b>
            </div>
          </div>

          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label htmlFor='programDate'>Start Date</label>
              <input
                type='text'
                className='form-control'
                id='programTitle'
                name='date'
                disabled
                value={this.props.date.toDateString()}
                // value={this.props.date ? this.formatDate() : ""}
              />
            </div>
            <div className='form-group col-md-6'>
              <label htmlFor='programTitle'>Title</label>
              <sup>*</sup>
              <input
                type='text'
                className='form-control'
                id='programTitle'
                name='title'
                maxLength='50'
                placeholder='Enter Program Title'
                onChange={this.handleInputChange}
                value={this.props.program.title}
              />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='programSubtitle'>Subtitle</label>
            <input
              type='text'
              className='form-control'
              id='programSubtitle'
              name='subTitle'
              maxLength='50'
              placeholder='Enter Program Subtitle'
              onChange={this.handleInputChange}
              value={this.props.program.subTitle}
            />
          </div>
          <div className='form-row'>
            <div className='form-group col-md-4'>
              <label htmlFor='programStartTime'>Start Time</label>
              {/* <input
              <sup>*</sup>
              <input
                type='time'
                step='1'
                className='form-control'
                id='programStartTime'
                name='start'
                placeholder='Enter Start Time'
                onChange={this.handleInputChange}
                value={this.props.program.start}
              /> */}
              <sup>*</sup>
              <TimeField
                value={this.props.program.start}
                onChange={value => this.handleTimeChange('start', value)}
                showSeconds={true}
                name='start'
                className='form-control'
                style={{ width: '100%' }}
              />
            </div>
            <div className='form-group col-md-4'>
              <label htmlFor='programEndTime'>End Time</label>
              {/* <input
              <sup>*</sup>
              <input
                type='time'
                step='1'
                className='form-control'
                id='programEndTime'
                name='end'
                onChange={this.handleInputChange}
                placeholder='Enter End Time'
                value={this.props.program.end}
              /> */}
              <sup>*</sup>
              <TimeField
                value={this.props.program.end}
                onChange={value => this.handleTimeChange('end', value)}
                showSeconds={true}
                name='end'
                className='form-control'
                style={{ width: '100%' }}
              />
            </div>
            <div className='form-group col-md-4'>
              <label htmlFor='programDuration'>Duration</label>
              <input
                disabled
                type='text'
                className='form-control'
                name='duration'
                id='programDuration'
                placeholder='00:00:00'
                value={this.props.program.duration}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-4'>
              <label htmlFor='programDate'>Producer 1</label>
              <input
                type='text'
                className='form-control'
                id='producer1'
                name='producer1'
                maxLength='6'
                placeholder='Enter The Producer 1'
                onChange={this.handleInputChange}
                value={this.props.program.producer1}
              />
            </div>
            <div className='form-group col-md-4'>
              <label htmlFor='programTitle'>Producer 2</label>
              <input
                type='text'
                className='form-control'
                id='producer2'
                name='producer2'
                maxLength='6'
                placeholder='Enter The Producer 2'
                onChange={this.handleInputChange}
                value={this.props.program.producer2}
              />
            </div>
            <div className='form-group col-md-4'>
              <label htmlFor='programDate'>Production Number</label>
              <input
                type='text'
                className='form-control'
                id='productionNo'
                name='productionNo'
                maxLength='6'
                placeholder='Enter The production Number'
                onChange={this.handleInputChange}
                value={this.props.program.productionNo}
              />
            </div>
          </div>
          <div className='d-flex flex-wrap'>
            {Object.entries(programSelectOptions).map(([key, value]) =>
              this.renderSelectInputs(key, value)
            )}
          </div>
          <div className='form-row'>
            <div className='col'>
              <button type='submit' className='btn btn-primary' disabled={this.state.isSubmitting}>
                {this.props.program.id ? 'Save changes' : 'Create Show'}
              </button>
              <button type='reset' className='btn btn-secondary ml-3' onClick={this.resetForm}>
                Reset
              </button>
            </div>
          </div>
        </form>
      );
    }
  }
}
export default connect(
  null,
  { showErrorToast }
)(ProgramDetailsForm);

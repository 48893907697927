import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import { toggleCalendar } from '../redux/action-creators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const OtnCalendar = ({ date, onDateChange, showCalendar, toggleCalendar }) => {
  //This method prevents unnecessary rendering
  // refer https://reactjs.org/docs/react-component.html#shouldcomponentupdate
  // shouldComponentUpdate(nextProps) {
  //   if (this.props.date.getTime() === nextProps.date.getTime()) {
  //     return false;
  //   }
  //   return true;
  // }
  //render() {
  // const [showCalendar, toggleCalendar] = useState(false);
  return (
    <div className='block'>
      <div className='clearfix'>
        <div className='float-left'>
          <FontAwesomeIcon
            style={{ marginRight: '10px', cursor: 'pointer' }}
            icon={showCalendar ? 'minus' : 'plus'}
            onClick={toggleCalendar}
          />
          <span className='font-weight-bold'>{date.toDateString()}</span>
        </div>
        <div className='float-right'>
          <button className='btn btn-warning' onClick={() => onDateChange(new Date())}>
            Today
          </button>
        </div>
      </div>
      <div style={{ display: `${showCalendar ? 'block' : 'none'}` }}>
        <Calendar
          className='mx-auto calendar-card'
          value={date}
          onChange={date => onDateChange(date)}
        />
      </div>
    </div>
  );
  //}
};
const mapStateToProps = state => {
  return {
    showCalendar: state.showCalendar
  };
};
export default connect(
  mapStateToProps,
  { toggleCalendar }
)(React.memo(OtnCalendar));

import React, { Fragment } from 'react';
import Header from './Header';
import logo from '../logo.jpg';
import DashboardMain from './DashboardMain';
import Modal from './Modal';
import ExportCsv from './ExportCsv';
import { addToast } from '../redux/action-creators';
import { connect } from 'react-redux';
class Dashboard extends React.Component {
  state = {
    showExportModal: false
  };
  toggleExportModal = () => {
    this.setState({ showExportModal: !this.state.showExportModal });
  };
  render() {
    const { selectedStation } = this.props;
    return (
      <Fragment>
        <Header
          selectedStation={selectedStation}
          logo={logo}
          toggleExportModal={this.toggleExportModal}
        />
        <DashboardMain selectedStation={selectedStation} />
        {this.state.showExportModal ? (
          <Modal
            show={this.state.showExportModal}
            handleClose={() => this.setState({ showExportModal: false })}
            showCloseBtn={false}
            title={'Export - Select Date'}
          >
            <ExportCsv />
          </Modal>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return { selectedStation: state.station };
};
export default connect(
  mapStateToProps,
  { addToast }
)(Dashboard);

import React, { Fragment } from "react";
import Program from "./Program";
import Loading from "./Loading";
import Modal from "./Modal";
import CopyPrograms from "./CopyPrograms";
import { connect } from "react-redux";
import { showErrorToast } from "../redux/action-creators";
import { dateFormat } from "../service/Util";

class LogSheet extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPrograms: [],
      copyProgramModal: false,
      date: ""
    };
  }

  // componentWillUnmount() {
  //   this.setState({ selectedPrograms: [] });
  // }

  selectBox() {
    return <input type='checkbox' />;
  }

  onProgramCheckboxClick = programId => {
    var selectedPrograms = [...this.state.selectedPrograms];
    if (selectedPrograms.includes(programId)) {
      const index = selectedPrograms.indexOf(programId);
      selectedPrograms.splice(index, 1);
      this.setState({ selectedPrograms: selectedPrograms });
      // store.dispatch(programSelcetionToast(this.state.selectedPrograms));
    } else {
      selectedPrograms.push(programId);
      this.setState({ selectedPrograms: selectedPrograms });
    }
  };

  onCopyProgramClicked = () => {
    if (this.state.selectedPrograms.length === 0) {
      this.props.showErrorToast("Please select atleast one program to Copy");
      return;
    }
    this.setState({ copyProgramModal: true });
  };

  clearDay = () => {
    let data = {
      clearDay: dateFormat(this.props.date)
    };
    if (window.confirm("Are you sure you want to clear whole day ?")) {
      this.props.clearDay(data);
    }
  };

  renderShowList = () => {
    // this.setState({ selectedPrograms: [] });
    if (this.props.listLoading) {
      return <Loading />;
    }
    if (!this.props.programs || Object.keys(this.props.programs).length === 0) {
      return <p>No Shows for the selected date</p>;
    } else {
      return (
        <div className='logSheet'>
          <table className='table table-hover'>
            <thead>
              <tr>
                <th scope='col'>{""}</th>
                <th scope='col'>#</th>
                <th scope='col'>Start</th>
                <th scope='col'>End</th>
                <th scope='col'>Title/Advertiser</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(this.props.programs).map(([key, program]) => (
                <Program
                  key={key}
                  index={key}
                  program={program}
                  editProgram={this.props.editProgram}
                  deleteProgram={this.props.deleteProgram}
                  onProgramCheckboxClick={this.onProgramCheckboxClick}
                  isSelected={this.state.selectedPrograms.includes(program.id)}
                />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.date !== this.state.date) {
      this.setState({ date: newProps.date, selectedPrograms: [] });
    }
  }

  render() {
    return (
      <Fragment>
        <div className='block'>
          <div className='row clearfix'>
            <div className='col'>
              <div className='float-left'>
                <b>On Air Log Sheet for </b>
                {this.props.date.toDateString()}
              </div>
              <div className='float-right'>
                <button
                  className='btn-warning btn'
                  onClick={this.props.addShow}
                >
                  +Add Show
                </button>
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col'>{this.renderShowList()}</div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col'>
            <button
              className='btn btn-primary mr-2'
              onClick={this.props.copyDays}
            >
              Copy Days
            </button>
            <button
              className='btn btn-primary mr-2 '
              onClick={() => this.onCopyProgramClicked()}
            >
              Copy programs
            </button>

            <button className='btn btn-primary mr-2' onClick={this.clearDay}>
              Clear Day
            </button>
          </div>
        </div>

        {this.state.copyProgramModal ? (
          <div className='copyPrograms'>
            <Modal
              className='avsdhjasv'
              show={this.state.copyProgramModal}
              handleClose={() => this.setState({ copyProgramModal: false })}
              showCloseBtn={false}
              size='medium'
              title='Copy Programs'
            >
              <CopyPrograms
                selectedPrograms={this.state.selectedPrograms}
                date={this.props.date}
              />
            </Modal>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

export default connect(
  null,
  { showErrorToast }
)(LogSheet);

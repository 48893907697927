import React from 'react';
import AuthService from '../service/AuthService';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import { Provider } from 'react-redux';
import store from '../redux/store';
import Toasts from './Toasts';

const Router = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" component={App} /> */}
          <Route path='/login' component={Login} />
          <PrivateRoute exact path='/' component={Dashboard} />
        </Switch>
      </BrowserRouter>
      <Toasts />
    </Provider>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !AuthService.isLoggedIn() ? (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
export default Router;

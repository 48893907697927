import React from 'react';
import ApiService, { API_ENDPOINTS } from '../service/ApiService';
import { LogFileStructure } from '../models/Program';

//refer https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2
class ExportCsv extends React.Component {
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  today = new Date();
  monthRef = React.createRef();
  yearRef = React.createRef();
  constructor() {
    super();
    this.state = {
      disableSubmit: false
    };
  }
  toggleLoading = () => {
    this.setState({ disableSubmit: !this.state.disableSubmit });
  };
  convertToTxt = programs => {
    let str = '';
    for (const [index, program] of programs.entries()) {
      let line = '';
      for (const [fieldIndex, row] of LogFileStructure.entries()) {
        let field = row['field'];
        let value = row['default'] || '';
        if (program[field]) {
          switch (row['format']) {
            case 'HHMMSS':
              value = program[field].replace(/:/g, '');
              break;
            case 'YYMMDD':
              value = program[field].replace(/-/g, '').substr(2);
              break;
            default:
              value = program[field];
              break;
          }
        }
        //Adding white space if value length is less than the required length
        line += value.padEnd(row['length']);
      }
      //console.log(line.length);
      str += line + '\n';
    }
    //console.log(str);
    return str;
  };
  exportToCsv = programs => {
    let csv = this.convertToTxt(programs);
    //console.log(csv);
    // let exportedFileName = `OTN${this.monthRef.current.value.padStart(
    //   2,
    //   '0'
    // )}${this.yearRef.current.value.substr(2)}.txt`;

    let exportedFileName = `${localStorage.getItem(
      'station_identifier'
    )}${this.yearRef.current.value.substr(
      2
    )}${this.monthRef.current.value.padStart(2, '0')}.txt`;
    let blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    if (navigator.msSaveBlob) {
      //IE 10+
      navigator.msSaveBlob(blob, exportedFileName);
    } else {
      let link = document.createElement('a');
      if (link.download !== 'undefined') {
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    this.toggleLoading();
  };
  onFormSubmit = event => {
    event.preventDefault();
    const data = {
      month: this.monthRef.current.value,
      year: this.yearRef.current.value
    };
    this.toggleLoading();
    //Call API to get programs for the selected month
    ApiService.request(
      'POST',
      API_ENDPOINTS.FETCH_PROGRAMS_BY_MONTH,
      data,
      resp => {
        let programs = resp.data.programs;
        if (programs && programs.length === 0) {
          alert('No programs found for the selected month');
          return;
        }
        this.exportToCsv(programs);
      },
      () => this.toggleLoading()
    );
    //this.exportToCsv(samplePrograms);
  };
  generateYearsDropdown = () => {
    let minOffset = 5;
    let maxOffset = 10;
    let year = this.today.getFullYear() - minOffset;
    let options = [];
    for (let i = 0; i <= maxOffset; i++) {
      year = year + 1;
      options.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return options;
  };
  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <div className='form-row align-items-center'>
          <div className='col-auto'>
            <select
              defaultValue={this.today.getMonth() + 1}
              ref={this.monthRef}
              className='form-control'
            >
              {Object.entries(this.monthNames).map(([key, month]) => {
                return (
                  <option key={key} value={parseInt(key) + 1}>
                    {month}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='col-auto'>
            <select
              defaultValue={this.today.getFullYear()}
              ref={this.yearRef}
              className='form-control'
            >
              {this.generateYearsDropdown()}
            </select>
          </div>
          <div className='col-auto'>
            <button
              type='submit'
              disabled={this.state.disableSubmit}
              className='btn btn-primary'
            >
              Export
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default ExportCsv;

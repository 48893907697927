import { combineReducers } from 'redux';
import { toasts } from './toasts';
import station from './station';
import { toggleCalendar } from './toggleCalendar';

//Turns an object whose values are different reducer functions, into a single reducer function.
//It will call every child reducer, and gather their results into a single state object, whose keys correspond to the keys of the passed reducer functions.

const rootReducer = combineReducers({
  toasts: toasts,
  station: station,
  showCalendar: toggleCalendar
});
export default rootReducer;

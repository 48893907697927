import { SET_STATION } from '../action-creators';

const station = (state = {}, action) => {
  switch (action.type) {
    case SET_STATION:
      return { ...action.payload };
    default:
      return state;
  }
};
export default station;

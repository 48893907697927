import React, { Fragment } from 'react';
import ApiService, { API_ENDPOINTS } from '../service/ApiService';

class AddStation extends React.Component {
  stationNameRef = React.createRef();
  stationIdentifierRef = React.createRef();

  onFormSubmit = e => {
    e.preventDefault();
    let data = {
      name: this.stationNameRef.current.value,
      identifier: this.stationIdentifierRef.current.value
    };
    console.log(data);
    ApiService.request('POST', API_ENDPOINTS.ADD_STATION, data, resp => {
      if (resp) {
        document.getElementById('addStationForm').reset();
        this.fetchStationList();
      }
    });
  };

  fetchStationList = () => {
    ApiService.request('GET', API_ENDPOINTS.STATIONS_LIST, {}, resp => {
      this.props.setStation(resp.data.stations);
    });
  };
  render() {
    return (
      <Fragment>
        <form id='addStationForm' onSubmit={this.onFormSubmit}>
          <div className='form-group row'>
            <label className='col col-6'>Station Name</label>
            <input
              type='text'
              className='form-control col col-3'
              ref={this.stationNameRef}
            />
          </div>
          <div className='form-group row'>
            <label className='col col-6'>Station Identifier</label>
            <input
              type='text'
              className='form-control col col-3'
              ref={this.stationIdentifierRef}
            />
          </div>

          <button type='submit' className='btn btn-primary  row'>
            Submit
          </button>
        </form>
      </Fragment>
    );
  }
}
export default AddStation;

import React from 'react';
import '../css/App.css';
import OtnCalendar from './OtnCalendar';
import loader from '../loader.svg';
import LogSheet from './LogSheet';
import ProgramDetailsForm from './ProgramDetailsForm';
import Program from '../models/Program';
import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import programSelectOptions from '../program-details';
import Modal from './Modal';
import CopyDays from './CopyDays';
//import ExportCsv from './ExportCsv';
import ApiService, { API_ENDPOINTS } from '../service/ApiService';
import { dateFormat } from '../service/Util';

library.add(faEdit, faTrash, faPlus, faMinus);

class DashboardMain extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      date: new Date(),
      program: new Program(),
      programs: [],
      listLoading: false,
      formLoading: false,
      pageLoad: false,
      showCopyDaysModal: false,
      showExportModal: false
    };
    //this.fetchLogSheet();
  }
  componentDidMount() {
    document.title = 'OTN Dashboard';
    this.setState({
      date: new Date()
    });
    if (this.props.selectedStation) {
      this.fetchLogSheet();
    }
  }
  componentDidUpdate(prevProps) {
    const prevSelectedStation = prevProps.selectedStation;
    const { selectedStation } = this.props;
    if (prevSelectedStation && selectedStation && prevSelectedStation.id !== selectedStation.id) {
      this.fetchLogSheet();
    }
  }
  //function with API call to fetch all the progrmas selceted dates
  fetchProgramList = requestData => {
    this.toggleListLoading();
    ApiService.request(
      'POST',
      API_ENDPOINTS.PROGRAM_LIST,
      requestData,
      resp => {
        this.setState({
          programs: [...resp.data.programs],
          listLoading: false
        });
        //this.forceUpdate();
      },
      () => {
        this.toggleListLoading();
      },
      false
    );
  };

  //function with API call to get the details of selected program
  getDetailsOfSelectedProgram = body => {
    ApiService.request('POST', API_ENDPOINTS.PROGRAM_DETAIL, body, resp => {
      this.setState({ program: resp.data.program });
    });
  };

  //function wit API call to delete selected program
  deleteSelectedProgram = body => {
    ApiService.request('POST', API_ENDPOINTS.DELETE_PROGRAM, body);
  };

  fetchLogSheet = () => {
    // var date = this.state.date;
    var reqestBody = {
      date: dateFormat(this.state.date)
    };
    //function fetch list of programs by API call
    this.fetchProgramList(reqestBody);
  };

  onDateChange = date => {
    this.setState({ date: date, program: new Program(), programs: null }, this.fetchLogSheet);
  };
  addShow = () => {
    //refresh program state
    this.setState({ program: new Program() });
  };
  editProgram = (programId, index) => {
    let body = {
      id: programId
    };

    this.getDetailsOfSelectedProgram(body);
  };

  deleteProgram = (programId, index) => {
    let body = {
      id: programId
    };
    //console.log(programId, index);

    // this.deleteSelectedProgram(body);
    ApiService.request('POST', API_ENDPOINTS.DELETE_PROGRAM, body, () => {
      const Programs = { ...this.state.programs };
      delete Programs[index];
      this.setState({ programs: Programs });
    });
  };

  clearDay = date => {
    ApiService.request('POST', API_ENDPOINTS.CLEAR_DAY, date, () => {
      this.setState({ programs: null });
    });
  };
  programInputChange = program => {
    // let value = e.target.value;
    // let name = e.target.name;
    // let program = {...this.state.program};
    this.setState({ program: program });
  };
  saveProgram = program => {
    // this.setState({ pageLoad: true });
    //Call backend api to save program

    this.setState({ formLoading: true });
    var date = this.state.date;
    program.date = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-').toString();
    ApiService.request(
      'POST',
      API_ENDPOINTS.SAVE_PROGRAM,
      program,
      resp => {
        this.setState({ program: new Program() });
        this.toggleFormLoading();
        this.fetchLogSheet();
      },
      () => this.toggleFormLoading()
    );
  };

  toggleFormLoading() {
    this.setState({ formLoading: !this.state.formLoading });
  }
  toggleListLoading() {
    this.setState({ listLoading: !this.state.listLoading });
  }
  renderLoader() {
    if (this.state.pageLoad) {
      return (
        <div className='loading-screen'>
          <div className='loading-img'>
            <img src={loader} alt='loader' />
          </div>
        </div>
      );
    }
  }
  render() {
    const { selectedStation } = this.props;
    if (!selectedStation || !selectedStation.id) {
      return <h3 className='text-center'>Please select a station.</h3>;
    }
    return (
      <div className='wrapper mb-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-5'>
              <OtnCalendar date={this.state.date} onDateChange={this.onDateChange} />
              <LogSheet
                programs={this.state.programs}
                date={this.state.date}
                addShow={this.addShow}
                editProgram={this.editProgram}
                clearDay={this.clearDay}
                deleteProgram={this.deleteProgram}
                listLoading={this.state.listLoading}
                copyDays={() => this.setState({ showCopyDaysModal: true })}
                exportCsv={() => this.setState({ showExportModal: true })}
              />
            </div>
            <div className='col-7'>
              <div className='block'>
                <ProgramDetailsForm
                  date={this.state.date}
                  program={this.state.program}
                  onFormSubmit={this.saveProgram}
                  formLoading={this.state.formLoading}
                  handleInputChange={this.programInputChange}
                  programSelectOptions={programSelectOptions}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showCopyDaysModal ? (
          <Modal
            show={this.state.showCopyDaysModal}
            handleClose={() => this.setState({ showCopyDaysModal: false })}
            showCloseBtn={false}
            size='large'
            title='Copy Days'
          >
            <CopyDays
              date={this.state.date}
              handleSave={() => this.setState({ showCopyDaysModal: false })}
            />
          </Modal>
        ) : (
          ''
        )}

        {this.renderLoader()}
      </div>
    );
  }
}

export default DashboardMain;

export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const SET_STATION = 'SET_STATION';
export const TOGGLE_CALENDAR = 'TOGGLE_CALENDAR';
let id = 0;
export function showSuccessToast(message) {
  return {
    type: SHOW_SUCCESS_TOAST,
    payload: {
      message,
      id: id++
    }
  };
}
export function showErrorToast(message) {
  return {
    type: SHOW_ERROR_TOAST,
    payload: {
      message,
      id: id++
    }
  };
}
export function addToast(type, message) {
  return {
    type: type,
    payload: {
      message,
      id: id++
    }
  };
}
export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    payload: {
      id
    }
  };
}
export function setStation(station) {
  return {
    type: SET_STATION,
    payload: {
      ...station
    }
  };
}
export function toggleCalendar() {
  return {
    type: TOGGLE_CALENDAR
  };
}

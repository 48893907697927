import React from 'react';
const Modal = ({ handleClose, show, children, showCloseBtn, size, title }) => {
  const showHideClassName = show ? 'show' : '';
  const modalStyle = show ? { display: 'block' } : { display: 'none' };
  const modalSize = size === 'large' ? 'modal-lg' : '';
  return (
    <div
      id='myModal'
      className={'modal fade ' + showHideClassName}
      role='dialog'
      style={modalStyle}
    >
      <div className={`modal-dialog ${modalSize}`}>
        <div className='modal-content'>
          <div className='modal-header'>
            {title ? <h5 className='modal-title'>{title}</h5> : ''}
            <button type='button' onClick={handleClose} className='close'>
              &times;
            </button>
          </div>
          <div className='modal-body'>{children}</div>
          {showCloseBtn ? (
            <div className='modal-footer'>
              <button onClick={handleClose} type='button' className='btn btn-default'>
                Close
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default Modal;

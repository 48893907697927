import React from 'react';
import '../css/custom.scss';
import AuthService from '../service/AuthService';
import { Redirect } from 'react-router-dom';
import logo from '../logo.jpg';
export default class Login extends React.Component {
  componentDidMount() {
    document.title = 'OTN Login';
  }
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: AuthService.isLoggedIn()
    };
  }

  emailRef = React.createRef();
  passwordRef = React.createRef();
  onLogin = event => {
    event.preventDefault();
    let email = this.emailRef.current.value;
    let password = this.passwordRef.current.value;

    if (!email) {
      alert('Please enter an email ID');
      return;
    }
    if (!password) {
      alert('Please enter a password');
      return;
    }
    //Call authenticate method of AuthService constant and pass email, password and callback function
    AuthService.authenticate({ email, password }, () => {
      this.setState({ isLoggedIn: true });
    });
  };
  renderLoginForm = () => {
    return (
      <div className='container'>
        <div className='row align-middle mt-5'>
          <div className='col-md-4 offset-md-4'>
            <div className='logo mb-4'>
              <img src={logo} alt='logo' className='mx-auto d-block' />
            </div>
            <h3 className='text-center'>Login</h3>
            <form onSubmit={this.onLogin.bind(this)}>
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input
                  ref={this.emailRef}
                  name='email'
                  type='email'
                  id='email'
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <label htmlFor='password'>Password</label>
                <input
                  ref={this.passwordRef}
                  name='password'
                  type='password'
                  id='password'
                  className='form-control'
                />
              </div>
              <button type='submit' className='btn btn-primary'>
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };
  render() {
    //get from(referrer) from props.location.state if set
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let { isLoggedIn } = this.state;
    //if isLoggedIn is true then redirect to the referrer OR Redirect to root after successful login
    if (isLoggedIn) return <Redirect to={from} />;
    //render login form if user not logged in
    return this.renderLoginForm();
  }
}

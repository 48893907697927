import React, { Fragment } from 'react';
import ApiService, { API_ENDPOINTS } from '../service/ApiService';
import Modal from './Modal';
import AddStation from './AddStation';
import { connect } from 'react-redux';
import { setStation } from '../redux/action-creators';
class StationsList extends React.Component {
  state = {
    stationsList: [],
    AddShowModal: false,
    SelectedStationId: ''
  };

  componentDidMount = () => {
    ApiService.request('GET', API_ENDPOINTS.STATIONS_LIST, {}, resp => {
      this.setState({ stationsList: [...resp.data.stations] });
      if (resp.data.stations.length > 0) {
        const station = resp.data.stations[0];
        localStorage.setItem('station_identifier', station.identifier);
        localStorage.setItem('station_id', station.id);
        this.setState({ SelectedStationId: station.id });
        this.dispatchSetStation(station);
      }
    });
  };

  onOptionChnage = e => {
    // e.target.value === 'AddNewStation'
    //   ? this.setState({ AddShowModal: true })
    //   : localStorage.setItem('station_identifier', e.target.value);

    if (e.target.value === 'AddNewStation') {
      this.setState({ AddShowModal: true });
    } else {
      this.setState({ SelectedStationId: e.target.value });

      const station =
        this.state.stationsList.find(
          station => station.id === parseInt(e.target.value)
        ) || {};
      localStorage.setItem('station_identifier', station.identifier);
      localStorage.setItem('station_id', e.target.value);
      this.dispatchSetStation(station);
    }
  };
  dispatchSetStation = station => {
    this.props.setStation(station);
  };
  setStationList = list => {
    //this.setState({ stationsList: list });

    this.setState({
      stationsList: [...list]
    });

    console.log(this.state.stationsList);
  };
  render() {
    const { stationsList } = this.state;
    return (
      <Fragment>
        <div>
          {this.state.AddShowModal ? (
            <Modal
              show={this.state.AddShowModal}
              handleClose={() => {
                this.setState({ AddShowModal: false });
              }}
              size='medium'
              title='Add Station'
            >
              <AddStation setStation={this.setStationList} />
            </Modal>
          ) : (
            ''
          )}
        </div>

        <div className='dropdown '>
          <select
            className='dropdown-toggle avatar-user-image  form-control'
            id='selectStationOrAdd'
            //onSelect={this.onOptionChnage}
            onChange={this.onOptionChnage}
            value={this.state.SelectedStationId}
          >
            <option />
            <option value='AddNewStation'>Add New Station</option>

            {/* <div className='dropdown-menu '> */}
            {stationsList.map((station, key) => (
              <option
                key={key}
                className='dropdown-item'
                value={station.id}
                name={station.identifier}
              >
                {station.name}
              </option>
            ))}
            {/* </div> */}
          </select>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  null,
  { setStation }
)(StationsList);

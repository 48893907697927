import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthService from '../service/AuthService';
import StationsList from './StationsList';

const Header = props => {
  return (
    <nav className='navbar'>
      <div className='container'>
        <a href='/'>
          <img src={props.logo} height='30' alt='' />
        </a>
        <div className='float-left'>
          <div className='btn '>
            <StationsList />
          </div>

          <button
            className='btn btn-primary mr-2'
            onClick={props.toggleExportModal}
          >
            Export
          </button>
          <button
            className='btn btn-danger '
            onClick={() => AuthService.signout(() => props.history.push('/'))}
          >
            LOGOUT
          </button>
        </div>
      </div>
    </nav>
  );
};
// refer https://stackoverflow.com/questions/53539314/what-is-withrouter-for-in-react-router-dom
export default withRouter(Header);
